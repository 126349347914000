import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "Faculty";

export const getFacultiesUrl = (studentTpeId) =>
	`${baseUrl}/${studentTpeId}/faculties`;
export const getApplicationFacultiesUrl = (applicationTypeId) =>
	`${baseUrl}/${applicationTypeId}/application-faculty`;
export const getAllDeansUrl = (filter) =>
	`${baseUrl}/deans?${generateUrlParams(filter)}`;
export const assignDeantoFacultyUrl = () => `${baseUrl}/assign-dean-to-faculty`;
