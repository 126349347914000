import { Route } from "react-router-dom";
import { Layout } from "../../ui_elements";

const MainRouter = ({
	component,
	path,
	exact,
	purpose,
	title,
	user,
	...rest
}) => {
	let Component = component;

	return (
		<Route
			exact={exact}
			path={path}
			{...rest}
			render={(props) => {
				return (
					<Layout title={title} noHeader>
						<div className="p-4 p-lg-0">
							<Component {...rest} {...props} />
						</div>
					</Layout>
				);
			}}
		/>
	);
};

export { MainRouter };
