import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import img from "../../assets/svgs/menu-btn.svg";
import "./buttonDropdown.css";

export const ButtonDropdown = ({ buttonGroup }) => {
	const [showDropdown, toggleShowDropdown] = useState(false);
	let [referencerElement, setReferenceElement] = useState();
	let [popperElement, setPopperElement] = useState();

	let { styles, attributes } = usePopper(referencerElement, popperElement, {
		placement: "bottom-start"
	});

	let btnRef = useRef();

	useEffect(() => {
		function handleClickOutside(e) {
			if (btnRef.current && !btnRef.current.contains(e.target)) {
				toggleShowDropdown(false);
			}
		}

		document.addEventListener("click", handleClickOutside);
		return () => document.removeEventListener("click", handleClickOutside);
	}, []);

	return (
		<div class="btn_drop_down_container" ref={setReferenceElement}>
			<button
				ref={btnRef}
				class="btn_dropdow border-0"
				onClick={() => toggleShowDropdown(!showDropdown)}
			>
				<img src={img} alt="" />
			</button>

			{showDropdown && (
				<ButtonContainer
					dropRef={setPopperElement}
					buttonGroup={buttonGroup}
					styles={styles.popper}
					attributes={attributes.popper}
				/>
			)}
		</div>
	);
};

const ButtonContainer = ({ buttonGroup, dropRef, styles, attributes }) => {
	return createPortal(
		<>
			<div
				ref={dropRef}
				className="btn_container"
				style={styles}
				{...attributes}
			>
				{buttonGroup?.map((btn, i) => (
					<p role={"button"} key={i} onClick={btn?.onClick}>
						{btn?.name}
					</p>
				))}
			</div>
		</>,
		document.getElementById("portal")
	);
};
