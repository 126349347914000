import styles from "./auth_style.module.css";
import authBanner from "../../assets/images/authBannerImage.png";
export const AuthPageBackgroundWrapper = ({ children }) => {
	return (
		<div className={`d-flex ${styles.background}`}>
			<div className={`${styles.white} mt-5`}>{children}</div>

			<div className={styles.imageContainer}>
				<img src={authBanner} alt="auth_banner" />
			</div>
		</div>
	);
};
