export const baseURL = process.env.REACT_APP_API_URL;

//these endpoint url's also serve as query keys
export * from "./urlCategories/Authentication";
export * from "./urlCategories/Dashboard";
export * from "./urlCategories/Student";
export * from "./urlCategories/CourseRegistration";
export * from "./urlCategories/Results";
export * from "./urlCategories/Session";
export * from "./urlCategories/Enums";
export * from "./urlCategories/LecturerProfile";
export * from "./urlCategories/Department";
export * from "./urlCategories/PassportSignature";
export * from "./urlCategories/Level";
export * from "./urlCategories/Payment";
export * from "./urlCategories/Faculty";
export * from "./urlCategories/CourseManagement";
export * from "./urlCategories/AdmissionList";
export * from "./urlCategories/ResultManagement";
export * from "./urlCategories/RolesandClaims";
export * from "./urlCategories/Notices";
export * from "./urlCategories/SundryPayment";
export * from "./urlCategories/Application";
export * from "./urlCategories/MenuManagement";
export * from "./urlCategories/UserManagement";
export * from "./urlCategories/ApplicationInvoice";
export * from "./urlCategories/Select";
export * from "./urlCategories/PGApplication";
export * from "./urlCategories/DirectEntryApplication";
export * from "./urlCategories/JupebApplications";
export * from "./urlCategories/PutmeApplication";
export * from "./urlCategories/CountryStatesLGA";
export * from "./urlCategories/CSPGApplication";
export * from "./urlCategories/Level";
export * from "./urlCategories/CSNDApplication";
export * from "./urlCategories/JambList";
export * from "./urlCategories/DirectEntryApplication";
export * from "./urlCategories/PreDegreeApplication";
export * from "./urlCategories/Hostel";
export * from "./urlCategories/CCEApplication";
export * from "./urlCategories/SupplementaryPutmeApplication";
export * from "./urlCategories/Lecturer";
export * from "./urlCategories/Webhook";
export * from "./urlCategories/UserDocument";
export * from "./urlCategories/Olevel";
export * from "./urlCategories/SupplementaryApplication";
export * from "./urlCategories/TransferApplication";
export * from "./urlCategories/Notifications";
export * from "./urlCategories/DiplomaApplication";
export * from "./urlCategories/SandwichApplication";
export * from "./urlCategories/Finance";
export * from "./urlCategories/BorrowedCourseRegistration";

